import "../packs_common/globalFunctions"
import FastFaq from "../components/FastFaq"

import CookieConsentDialog from "../components/CookieConsentDialog"
import Amex from "../components/amex"
import GlobalCategoryNavbar from "../components/GlobalCategoryNavbar"
import Translations from "../components/Translations"
import { ComponentClass, FunctionComponent } from "react"
import ReactOnRails from "../reactOnRails"
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: 'b359da34-a4c6-4d85-bff8-6dd8b0f97d23',
  clientToken: 'pub36e6c00585498a5c72d36e0eb148dafb',
  site: 'datadoghq.eu',
  env: process.env.NODE_ENV,
  service:'fz-web',
  version: '1.0.0',
  sessionSampleRate: process.env.NODE_ENV === "production" ? 1 : 0,
  sessionReplaySampleRate: process.env.NODE_ENV === "production" ? 2 : 0,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'mask-user-input'
});

datadogRum.startSessionReplayRecording();

ReactOnRails.register({
  "Translations": Translations as FunctionComponent,
  "FastFaq": FastFaq as ComponentClass,
  "CookieConsentDialog": CookieConsentDialog as FunctionComponent,
  "GlobalCategoryNavbar": GlobalCategoryNavbar as FunctionComponent,
  "Amex": Amex as FunctionComponent,
});

import './application_minimal'
