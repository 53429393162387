import React from 'react';
import { Colors } from "../colors";
import { Spacing } from "../spacing";
import { rem } from "../style-utils"
import styled, { createGlobalStyle } from 'styled-components';

const FontImport = createGlobalStyle`
    @font-face {
        font-family: 'readly-glyphs';
        src: url(https://dnjpbxg2wcpr8.cloudfront.net/web/fonts/Readly-Glyphs.ttf);
    }
`

export enum EIconGlyph {
  ring          = "A",
  user_add      = "B",
  user          = "C",
  trash         = "D",
  star          = "E",
  star_filled   = "u",
  bubble        = "F",
  sort          = "G",
  settings      = "H",
  search        = "I",
  pin           = "J",
  newspaper     = "K",
  more          = "L",
  message       = "M",
  menu          = "N",
  magazine      = "O",
  logout        = "P",
  login         = "Q",
  locked        = "R",
  info          = "S",
  home          = "T",
  flag          = "U",
  filter_2      = "V",
  filter        = "W",
  faq           = "X",
  facebook      = "Y",
  error         = "a",
  edit          = "b",
  download      = "c",
  close         = "d",
  check         = "e",
  bookmark      = "f",
  bell_on       = "g",
  bell_off      = "h",
  arrow_up      = "i",
  arrow_right   = "j",
  arrow_left    = "k",
  arrow_down    = "l",
  chevron_up    = "m",
  chevron_right = "n",
  chevron_left  = "o",
  chevron_down  = "p",
  eye_on        = "q",
  eye_off       = "r",
  share_ios     = "s",
  share_android = "t",
  refresh       = "v",
  screen_off    = "w",
  screen_on     = "x",
}

const IconContainer = styled.span<{
  size: Spacing
  color: Colors
}>`
  font-family: "readly-glyphs";
  color: ${props => props.color ? props.color : Colors.FloralWhite};

  display: flex;
  user-select: none;

  width:  ${props => rem(props.size)};
  height: ${props => rem(props.size)};

  font-size:   ${props => rem(props.size)};
  line-height: ${props => rem(props.size)};
`

export type IconProps = {
  icon: EIconGlyph
  size: Spacing
  color?: Colors
};

export function Icon({
  icon,
  size,
  color
}: IconProps) {
  return (
    <>
      <FontImport/>
      <IconContainer size={size} className="notranslate" color={color}>
        {icon}
      </IconContainer>
    </>
  );
}
