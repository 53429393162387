import * as React from 'react';

// Describe article state with type
type ArticleState = {
  articles: Array<{}>
}
// Descripe article object with type
type Article = {
  body: string, 
  title: string,
  active: Boolean
}

const articleStyleActive = {
  backgroundColor:'#ffbb22',
  minHeight:'40px',
  marginTop:'10px',
  borderRadius: '40px',
  color:'white',
  textAlign: 'left',
  cursor: 'pointer',
} as React.CSSProperties

const articleStyleInactive = {
  backgroundColor:'#ffbb22',
  minHeight:'40px',
  marginTop:'10px',
  borderRadius: '10px',
  color:'white',
  textAlign: 'left',
  cursor: 'pointer', 
} as React.CSSProperties

const styleArrowImgActive = {
  width:'40px',
  padding:'10px',
  transform: 'rotate(180deg)',
  transition: 'transform 1s ease',
} as React.CSSProperties

const styleArrowImgInactive = {
  width:'40px',
  padding:'10px',
  transform: 'rotate(0deg)',
  transition: 'transform 1s ease',
} as React.CSSProperties

const bodyStyle = {
  padding: "18px",
  paddingTop: "10px"
} as React.CSSProperties

const titleStyle = {
 fontFamily:'Helvetica, Arial, sans-serif',
 fontSize:'13px',
 fontWeight: 'bold'
} as React.CSSProperties

const contentStyle = {
  color:'black',
  fontSize:'13px',
  fontFamily:'Helvetica, Arial, sans-serif',  
} as React.CSSProperties

export default class FastFaq extends React.Component<ArticleState>{
  public readonly state: Readonly<ArticleState> = {
    articles: [{}]
  }

  componentWillMount() {
    fetch('/api/support/articles/fast-faq')
    .then(response => response.json())
    .then(result => {
      let articles = result.data.response.results
      this.setState({ articles })
    });
  }

  handleClick(index){
    // This method handle the clicked article
    let {articles} = this.state // Get articles from state
    let active // Boolean to be checked if article should be active or not
    
    // If clicked article has property active undefined or false, is the article closed? 
    if (articles[index]["active"] !== true) { 
      active = true
    } else {
      active = false
    }

    // Close all articles
    for(let i in articles) {
      articles[i]["active"] = false
    }

    // Open or close the clicked article
    articles[index]["active"] = active
    this.setState({ articles })
  }
  
render() {
  let { articles }: { articles: Array<{}> } = this.state
  return(
      <div className="col-md-4 col-sm-6 col-lg-6 col-xl-3">
        
          {articles.map((article:Article, index:number) => {

            let { body, active }  = article
            let currentSyle = active ? styleArrowImgActive : styleArrowImgInactive
            let currentArticleStyle = active ? articleStyleInactive : articleStyleActive
            let DesctiptionComp

            if (active) { 
              // Inject html to be rendered inside the div
              DesctiptionComp = <div dangerouslySetInnerHTML={ { __html: body } } style={bodyStyle} /> 
            }

            return (
              <div onClick={() => {
                this.handleClick(index)
                  }} key={index}>
                
                  <div style={currentArticleStyle}>
                    <img style={currentSyle} src="https://readly-eu-campaign-assets.s3.amazonaws.com/uploads%2Fglobal_assets%2F45yppi3cfuc-whitearrow.svg"/>
                    <span style={titleStyle}>{article.title}</span>
                    <span style={contentStyle}>{DesctiptionComp}</span>
                  </div>
                </div>
            
            )
          })
          }
      </div>
  )
}

}
