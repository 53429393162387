import { getCampaignUuid, init, validateUser } from "@kunaico/amex-perks-sdk";
import React from "react";

export default function Amex ({amex_env} : {amex_env:string}) {

    const campUuid = getCampaignUuid()
    const merchantApiKey = amex_env == 'production' 
        ? 'c371058a32f9405bb26a43b5a7cc51ea' : '0a6266396ce74ad48549e4fca6a56a6e'
    
    const config = {
        mode: amex_env, // 'qa' or 'production'
        campaignUuid: campUuid,
        merchantApiKey: merchantApiKey,
    }

    init(config)
    validateUser()
    
    return null
}