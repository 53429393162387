export enum Spacing {
  none = 0,
  tiny = 4,
  xSmall = 8,
  small = 16,
  default = 24,
  medium = 32,
  large = 48,
  xLarge = 64,
}
