export enum Colors {
  FloralWhite = '#fffae9',
  ColombianBlue = 'rgba(175, 200, 255, 0.1)',
  SunglowYellow = 'rgba(255, 187, 34, 1)',
  SunglowDisabled = '#cc961b',

  MountainGray = '#868688',
  MarlinGray = '#3A3A46',
  RussianGray = '#1E1E28',
  RussianBlack = '#000000',
  RussinGradient = 'linear-gradient(180deg, rgba(30, 30, 40, 1) 0%, rgba(0, 0, 0, 1) 100%)',
  PaletteNext = '#F7F7F8',
  Gray = '#666666',

  FruitSalladGreen = '#51a951',
  TapestryRed = '#b77173',

  White = '#ffffff',
  Black = '#000000',
}
