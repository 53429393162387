import { Colors } from "../../bit/base/colors";
import { EIconGlyph, Icon } from "../../bit/base/icon";
import { Spacing } from "../../bit/base/spacing";
import { rem } from "../../bit/base/style-utils";
import { debug } from "console";
import React, { useState } from "react";
import styled from "styled-components";
import { ESize } from "../../styleConstants";
import Spacer from "../Blocks/spacer";
import { useIsMobile } from "../styleUtils";

const NavbarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    height: 68px;
    width: 100%;

    background-color: #FFFAE9;
    border: 1px solid #CCCCCC;

`

const NavbarItem = styled.div<{
    mobileLayout: boolean
}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: ${props => props.mobileLayout ? `0px ${rem(ESize.extra_small)}` : `0px`};

    /* width: ${props => props.mobileLayout ? "100%" : "fit-content"}; */
    width: fit-content;
    height: 28px;
    white-space: nowrap;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #3A3A46;
    color: ${props => props.mobileLayout ? "#999999" : "#3A3A46"};;

    a {
        color: #3A3A46;
    }
    a:hover  {
        font-weight: 700;
    }

`

const ScrollContainer = styled.div<{
    mobileLayout: boolean
}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: auto;
    height: 50px;

    
    animation: slide 15s linear infinite;

    @keyframes slide {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-33.3%, 0);
        }
    }

`
const ScrollWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 15px;

`

type Category = {
    title: string,
    path: string
}


const GlobalCategoryNavbar = ({
    categories
} : {
    categories: Category[]
}) => {
    const isMobile = useIsMobile()

    const [navCategories, setNavCategories] = useState<Category[]>(categories);

    const logTracking = () => {
        window.dataLayer.push({
            event: "cr1_homepage_global_nav_category_click"
        });
    }

    return (
        <NavbarWrapper>
            {isMobile ? 
                <ScrollWrapper>
                    <ScrollContainer mobileLayout={isMobile}>
                        {/* three times data so the animation works smoothly */}
                        {navCategories.map((category) => {
                            return (
                                <NavbarItem mobileLayout={isMobile}>
                                    <span>{category.title}</span>
                                </NavbarItem>
                            );
                        })}
                        {navCategories.map((category) => {
                            return (
                                <NavbarItem mobileLayout={isMobile}>
                                    <span>{category.title}</span>
                                </NavbarItem>
                            );
                        })}
                        {navCategories.map((category) => {
                            return (
                                <NavbarItem mobileLayout={isMobile}>
                                    <span>{category.title}</span>
                                </NavbarItem>
                            );
                        })}
                    </ScrollContainer>
                </ScrollWrapper>
                :
                <>
                    {navCategories.map((category) => {
                        return (
                            <NavbarItem mobileLayout={isMobile}>
                                <a href={category.path} onClick={logTracking}>
                                    <span>{category.title}</span>
                                </a>
                            </NavbarItem>
                        );
                    })}
                </>
            }

            <NavbarItem mobileLayout={isMobile}>
                <span style={{color: "#D9D9D9"}}>|</span>
            </NavbarItem>
            
            <NavbarItem mobileLayout={isMobile}>
                <Icon icon={EIconGlyph.magazine} color={Colors.Black} size={isMobile ? Spacing.small : Spacing.default}/>
                <Spacer width={isMobile ? ESize.extra_small : ESize.small}/>
                <a href="/products/magazines/categories"  onClick={logTracking}>
                    <span>All Categories</span>
                </a>
            </NavbarItem>
        </NavbarWrapper>
    );
};

export default GlobalCategoryNavbar;